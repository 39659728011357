<template>
	<NuxtLink to="/"><div class="logo"></div></NuxtLink>
</template>

<style scoped>
div.logo {
	--margin: 20px;
	background: linear-gradient(-33deg, var(--heading-color) 50%, rgb(187, 224, 227) 60%, var(--heading-color) 70%);
	background-size: 600% 600%;
	mask-image: url("~~/assets/logo.svg");
	mask-size: 100% calc(100px - 2 * var(--margin));
	width: calc(100% - 2 * var(--margin));
	height: calc(100px - 2 * var(--margin));
	margin: var(--margin);
	box-sizing: border-box;
}

div.logo:hover {
	animation: gradient 10s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
</style>
