import {useAsyncData, type AsyncData} from "#app";

let counter = 0;

/** Shorthand to execute an authenticated fetch request to the CRUD API endpoint. */
export const useCRUD = (q: () => any, opts?: any): AsyncData<any, any> => {
	return useFetch(
		computed(() => {
			return "/api/crud?" + encodeURIComponent(JSON.stringify(q()));
		}),
		{
			headers: useRequestHeaders(["cookie"]) as HeadersInit,
			...opts
		}
	);
};

/** Executes a fetch request with the user's cookie, so we can authenticatae the user. */
export function useAuthenticated<ResT = void>(url: string, opts: any = {}) {
	return useFetch<ResT>(url, {key: url, ...opts, headers: useRequestHeaders(["cookie"])});
}

/** Executes a fetch request with the user's cookie, so we can authenticatae the user. */
export function useAuthenticatedReactive<ResT = void>(url: () => string, opts: any = {}) {
	counter++;
	return useFetch<ResT>(computed(url), {...opts, headers: useRequestHeaders(["cookie"])});
}
