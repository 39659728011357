<template>
	<div style="width: 50%; margin-left: auto; margin-right: auto">
		<Title>Fout: {{ error.message }}</Title>
		<h2>Oeps, er ging iets mis</h2>
		<p>{{ error.message }}</p>
		<details>
			<summary>Technische details</summary>
			<pre v-html="error.description" />
		</details>
		<br />
		<p>
			Mocht het probleem aanhouden, lees dan
			<a href="https://dna.dialogiconderzoek.nl/nl/praktisch/it/iters">hier op de wiki</a> wie je om hulp kunt vragen.
		</p>
		<button @click="handleError">Doorgaan</button>
	</div>
</template>

<script setup>
const props = defineProps({
	error: Object
});

const handleError = () => clearError({redirect: "/"});
</script>
